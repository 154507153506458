import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

import { Cog6ToothIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ChannelModel } from '@mainApp/src/stores/Channel.model';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';
import { useTranslation } from 'react-i18next';

import { DeleteModaChannelContent } from '@mainApp/src/components/common/DeleteModaChannelContent';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';

import {
  useGetActiveCommunityDefaultChannelRedirect,
  useIsInExactChannel,
} from '@mainApp/src/hooks';

import { ChannelGroupModeEnum } from './channelGroupContextMenuContext';

import { MenuItem } from '../sidebar-context-menu/MenuItem';
import { Separator } from '../sidebar-context-menu/Separator';

type Props = {
  close: () => void;
  mode: ChannelGroupModeEnum;
  data: ChannelGroupModel | ChannelModel | null;
};
export function _Menu(props: Props) {
  const { close, mode, data } = props;
  const {
    communityStore,
    messageStore,
    channelStore,
    channelSettingsStore,
    interactiveStore,
  } = useMultipleInjection([
    IOC_TOKENS.communityStore,
    IOC_TOKENS.messageStore,
    IOC_TOKENS.channelSettingsStore,
    IOC_TOKENS.channelStore,
    IOC_TOKENS.interactiveStore,
  ]);

  const { t } = useTranslation(['common', 'channel']);
  const isInExactChannel = useIsInExactChannel(
    data instanceof ChannelModel ? data.serverData.id : undefined
  );

  const activeCommunity = communityStore.activeCommunity.data;

  const communityId = activeCommunity?.serverData?.id;

  const redirectToDefaultChannel =
    useGetActiveCommunityDefaultChannelRedirect();

  let readMenulabel = t('markAllAsRead');
  let readMenuAction = () => {
    const channelModel = data as ChannelGroupModel;
    channelStore.channelGroupMarkAllChannelsAsRead(
      communityId as string,
      channelModel.id
    );
    close();
  };

  let settingsMenuLabel = t('channel:channelGroupSettings');
  let settingsMenuAction = () => {
    alert('channel group settings');
    close();
  };

  let deleteMenuLabel = t('channel:deleteChannelGroup');
  let deleteMenuAction = () => {
    alert('delete channel group');
    close();
  };

  if (mode === ChannelGroupModeEnum.CHANNEL) {
    readMenulabel = t('markAsRead');
    readMenuAction = () => {
      messageStore.markMessagesAllAsRead();
      close();
    };

    settingsMenuLabel = t('channel:channelSettings.label');
    settingsMenuAction = () => {
      channelSettingsStore.setChannelModel(data as ChannelModel);
      channelSettingsStore.setIsShow(true);
      close();
    };

    deleteMenuLabel = t('channel:deleteChannel');
    deleteMenuAction = async () => {
      const channelModel = data as ChannelModel;
      if (!data) {
        throw new Error(`data is not defined`);
      }
      if (!(data instanceof ChannelModel)) {
        throw new Error(`data is not instance of ChannelModel`);
      }

      if (!channelModel?.parentGroupModel) {
        throw new Error(`channelModel.parentGroupModel is not defined`);
      }

      close();

      interactiveStore.setActiveContentType(
        InteractiveContentTypesEnum.OTHER,
        true
      );
      interactiveStore.setContent(
        <DeleteModaChannelContent
          channelServerData={data.serverData}
          onProceed={(isError) => {
            if (!isError && isInExactChannel) {
              // should redirect to the default only if the user in the channel that he is deleting. At the moment of next.js hot realod in dev mode this hook will return a null because the community at that moment is not defined. It will be defined after the hot reload is done.
              redirectToDefaultChannel?.();
            }
          }}
        />
      );

      interactiveStore.setInteractiveElementOpen(true);
    };
  }

  return (
    <ul className="rounded-[5px] border-[1px] py-[8px] shadow-shadow-menu-dekstop">
      {(activeCommunity?.isUserAdmin || activeCommunity?.isUserOwner) && (
        <>
          <MenuItem
            icon={<EyeIcon />}
            label={readMenulabel}
            onClick={readMenuAction}
          />
          <Separator />
        </>
      )}

      {(activeCommunity?.isUserAdmin || activeCommunity?.isUserOwner) && (
        <>
          <MenuItem
            icon={<Cog6ToothIcon />}
            label={settingsMenuLabel}
            onClick={settingsMenuAction}
          />

          <Separator />
          <MenuItem
            icon={<TrashIcon className="themed-text-error" />}
            className="themed-text-error"
            label={deleteMenuLabel}
            onClick={deleteMenuAction}
          />
        </>
      )}
    </ul>
  );
}

export const Menu = observer(_Menu);
