import { ChannelModel } from '@mainApp/src/stores/Channel.model';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';
import { createContext, useCallback, useContext, useState } from 'react';

export enum ChannelGroupModeEnum {
  CHANNEL = 'channel',
  GROUP = 'group',
}

type ChannelGroupContextMenuContextType = {
  isShow: boolean;
  data: ChannelGroupModel | ChannelModel | null;
  anchorEl: HTMLElement | null;
  mode: ChannelGroupModeEnum;
  showContextMenu: (
    anchorEl: HTMLElement,
    mode: ChannelGroupModeEnum,
    data: ChannelGroupModel | ChannelModel | null
  ) => void;
  hideContextMenu: () => void;
};

export const channelGroupContextMenuCtx =
  createContext<ChannelGroupContextMenuContextType>({
    isShow: false,
    data: null,
    anchorEl: null,
    mode: ChannelGroupModeEnum.GROUP,
    showContextMenu: (_anchorEl: HTMLElement) => {},
    hideContextMenu: () => {},
  });

type State = Omit<
  ChannelGroupContextMenuContextType,
  'showContextMenu' | 'hideContextMenu'
>;
type Props = {
  children: React.ReactNode;
};
export function ChannelGroupContextMenuProvider(props: Props) {
  const [state, setState] = useState<State>({
    isShow: false,
    anchorEl: null,
    data: null,
    mode: ChannelGroupModeEnum.GROUP,
  });

  const showContextMenu = useCallback(
    (
      anchorEl: HTMLElement,
      mode: ChannelGroupModeEnum,
      data: ChannelGroupModel | ChannelModel | null = null
    ) => {
      if (!anchorEl) throw new Error('anchorEl is not defined');

      setState((prev) => ({
        ...prev,
        mode,
        data,
        anchorEl,
        isShow: true,
      }));
    },
    []
  );

  const hideContextMenu = useCallback(() => {
    setState((prev) => ({
      ...prev,
      anchorEl: null,
      data: null,
      isShow: false,
    }));
  }, []);

  const value = {
    ...state,
    showContextMenu,
    hideContextMenu,
  };

  return (
    <channelGroupContextMenuCtx.Provider value={value}>
      {props.children}
    </channelGroupContextMenuCtx.Provider>
  );
}

export const useChannelGroupContextMenuContext = () => {
  return useContext(channelGroupContextMenuCtx);
};
