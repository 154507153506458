import { forwardRef, ReactNode } from 'react';

type DndItemRepresentationProps<T> = {
  children: (dataModel: T) => ReactNode;
  dataModel: T;
  cn?: string;
};

const _DndItemRepresentation = <T,>(
  { children, dataModel, cn, ...props }: DndItemRepresentationProps<T>,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <div {...props} className={cn} ref={ref}>
      {children(dataModel)}
    </div>
  );
};

export const DndItemRepresentation = forwardRef(_DndItemRepresentation) as <T>(
  props: DndItemRepresentationProps<T> & { ref?: React.Ref<HTMLDivElement> }
) => ReturnType<typeof _DndItemRepresentation>;
