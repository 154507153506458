import { DotsButton } from '@foundationPathAlias/components';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Popup from 'reactjs-popup';

import { Menu } from './Menu';

export function _MenuPopup() {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={closeModal}
      contentStyle={{
        width: 'auto',
        padding: '0',
        minWidth: 214,
        fontSize: '14px',
        borderRadius: 5,
      }}
      arrow={false}
      offsetX={-96}
      offsetY={22}
      trigger={
        <div>
          <DotsButton
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
      }
      position={['bottom center', 'left bottom']}
    >
      <Menu close={closeModal} />
    </Popup>
  );
}

export const MenuPopup = observer(_MenuPopup);
