import {
  Active,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  MeasuringStrategy,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useEffect, useRef, useState } from 'react';

// -----
import { useMultipleGroupsCollisionDetectionStrategy } from './useMultipleGroupsCollisionDetectionStrategy';
// -----
import { DndContainerRepresentation } from './DndContainerRepresentation';

import { createPortal } from 'react-dom';

import { classNames } from '@foundationPathAlias/utilities';
import { DndContainerSortable } from './DndContainerSortable';
import { DndItemRepresentation } from './DndItemRepresentation';
import { DndItemSortable } from './DndItemSortable';

const initialActiveModelData = {
  model: null,
  dndType: null,
};

export type ActiveModelDataType<ContainerT, ItemT> = {
  model: ContainerT | ItemT | null;
  dndType: 'container' | 'item' | null;
};

export type DndContainerSortablePropsType<ContainerT> = {
  id: string;
  items: string[];
  dataModel: ContainerT;
  disabled?: boolean;
  children: (
    params: DndContainerSortableRenderParamsType<ContainerT>
  ) => React.ReactNode;
};

export type DndContainerSortableRenderParamsType<ContainerT> = {
  setNodeRef: (node: HTMLElement | null) => void;
  style: React.CSSProperties;
  dataModel: ContainerT;
  attributes: React.HTMLAttributes<any>;
  listeners: Record<string, any>;
};

export type DndItemSortablePropsType<ItemT> = {
  id: string;
  index: number;
  dataModel: ItemT;
  children: (params: DndItemSortableRenderParamsType<ItemT>) => React.ReactNode;
};

export type DndItemSortableRenderParamsType<ItemT> = {
  setNodeRef: (node: HTMLElement | null) => void;
  style: React.CSSProperties;
  dataModel: ItemT;
  attributes: React.HTMLAttributes<any>;
  listeners: Record<string, any>;
};

export type DndReorderContainersPropsType<ContainerT, ItemT> = {
  /**
   * should contain an object with containers and items as [container.id]: items.id[]. It must be exactly IDS, not the model
   */
  containersWithItems: Record<string, string[]>;
  /**
   * should contain all containers and items flattened as [id]: model
   */
  containersAndItemsRegistry: Record<string, ContainerT | ItemT>;
  /**
   * renders the overlay when dragging a container
   */
  renderContainerDragOverlay: (dataModel: ContainerT) => JSX.Element;
  /**
   * you can use it to render the content of the container. In this way you have a control over the container content UI and can you your own custom components
   */
  ContainerContentComponent: (props: {
    dataModel: ContainerT;
    children: React.ReactNode;
  }) => JSX.Element;
  /**
   * you can use it to render the item. In this way you have a control over the item content UI and can you your own custom components
   */
  ItemComponent: (props: { dataModel: ItemT }) => JSX.Element;
  /**
   * as it's impossible to predict the structure of a model so you might provide a function to get the parent container id manually. Otherwrise internally will be used the path: `dataModel.serverData.parentId`
   */
  getParentContainerId?: (active: Active) => string;
  /**
   * renders the overlay when dragging an item
   */
  renderItemDragOverlay: (dataModel: ItemT) => JSX.Element;
  findContainerById: (id: string) => ContainerT;
  isMobile: boolean;
  /** when reorder items in the same container */
  onItemsReorder: (
    newOrderedItemsIds: string[],
    container: ContainerT
  ) => void | Promise<void>;
  onContainersReorder: (
    newOrderedContainersIds: string[],
    container: ContainerT
  ) => void | Promise<void>;
  onMoveItemToNewGroup: (
    item: ItemT,
    index: number,
    newGroupId: string
  ) => void | Promise<void>;
  onDragStart?: () => void;
  /** should be true if some error happened on BE etc. in this case everything will be reverted */
  reorderError: boolean;
  /** main wrapper  */
  wrapperCn?: string;
  containerChildrenCn?: string;
};

export function DndReorderContainers<ContainerT, ItemT>(
  props: DndReorderContainersPropsType<ContainerT, ItemT>
) {
  const {
    containersWithItems,
    containersAndItemsRegistry,
    renderContainerDragOverlay,
    ContainerContentComponent,
    ItemComponent,
    getParentContainerId,
    renderItemDragOverlay,
    findContainerById,
    isMobile,
    onItemsReorder,
    onContainersReorder,
    onMoveItemToNewGroup,
    onDragStart: onDragStartOuter,
    reorderError,
    wrapperCn,
    containerChildrenCn,
  } = props;

  const modelsRegistryRef = useRef(containersAndItemsRegistry);
  const previouslyDraggableTypeRef = useRef(null);

  const [containers, setContainers] = useState(containersWithItems);

  const [clonedContainers, setClonedContainers] = useState<any | null>(null);

  useEffect(() => {
    if (!reorderError) return;

    if (
      previouslyDraggableTypeRef.current === 'container' &&
      clonedContainerIds
    ) {
      setContainerIds(clonedContainerIds);
      setClonedContainerIds(null);
    }

    if (previouslyDraggableTypeRef.current === 'item') {
      setContainers(clonedContainers);
    }

    setClonedContainers(null);
  }, [reorderError]);

  const [activeId, setActiveId] = useState(null);
  const [activeModelData, setActiveModelData] = useState(
    initialActiveModelData
  );
  const activeModel = activeModelData.model;
  const lastOverId = useRef<UniqueIdentifier | null>(null);

  const recentlyMovedToNewContainer = useRef(false);

  const [containerIds, setContainerIds] = useState(Object.keys(containers));
  const [clonedContainerIds, setClonedContainerIds] = useState<null | string[]>(
    null
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const collisionDetectionStrategy =
    useMultipleGroupsCollisionDetectionStrategy({
      activeId,
      containers,
      recentlyMovedToNewContainer,
      lastOverId,
    });

  const findContainerByIdOrItemId = (id: UniqueIdentifier) => {
    if (id in containers) {
      return id;
    }

    return Object.keys(containers).find((key) =>
      containers[key].includes(id as string)
    );
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [containers]);

  useEffect(() => {
    modelsRegistryRef.current = containersAndItemsRegistry;
  }, [containersAndItemsRegistry]);

  return (
    <DndContext
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragOver={onDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={onDragCancel}
    >
      <div className={classNames('flex w-full flex-col', wrapperCn)}>
        <SortableContext
          items={[...containerIds]}
          strategy={verticalListSortingStrategy}
        >
          {containerIds.map((containerId) => (
            <DndContainerSortable<ContainerT>
              key={containerId}
              id={containerId}
              dataModel={findContainerById(containerId)}
              items={containers[containerId]}
            >
              {({ setNodeRef, attributes, listeners, ...otherDndParams }) => {
                return (
                  <DndContainerRepresentation<ContainerT>
                    ref={setNodeRef}
                    {...attributes}
                    {...listeners}
                    {...otherDndParams}
                  >
                    {(dataModel) => {
                      return (
                        <ContainerContentComponent dataModel={dataModel}>
                          <SortableContext
                            items={containers[containerId]}
                            strategy={verticalListSortingStrategy}
                          >
                            <div
                              className={classNames(
                                'my-[12px] flex w-full flex-col space-y-[12px]',
                                containerChildrenCn
                              )}
                            >
                              {containers[containerId].map((itemId, index) => {
                                const dataModel =
                                  modelsRegistryRef.current[itemId];

                                return (
                                  <DndItemSortable
                                    dataModel={dataModel}
                                    key={itemId}
                                    id={itemId}
                                    index={index}
                                  >
                                    {({
                                      setNodeRef,
                                      attributes,
                                      listeners,
                                      ...otherDndParams
                                    }) => {
                                      return (
                                        <DndItemRepresentation<ItemT>
                                          ref={setNodeRef}
                                          {...attributes}
                                          {...listeners}
                                          {...otherDndParams}
                                        >
                                          {(dataModel) => {
                                            return (
                                              <ItemComponent
                                                dataModel={dataModel}
                                              />
                                            );
                                          }}
                                        </DndItemRepresentation>
                                      );
                                    }}
                                  </DndItemSortable>
                                );
                              })}
                            </div>
                          </SortableContext>
                        </ContainerContentComponent>
                      );
                    }}
                  </DndContainerRepresentation>
                );
              }}
            </DndContainerSortable>
          ))}
        </SortableContext>
      </div>
      {global?.document?.body &&
        createPortal(
          <DragOverlay>
            {activeModel
              ? activeModelData.dndType === 'container'
                ? renderContainerDragOverlay(activeModel)
                : renderItemDragOverlay(activeModel)
              : null}
          </DragOverlay>,
          global.document.body
        )}
    </DndContext>
  );

  function handleDragStart(event: any) {
    if (isMobile) return;
    const { active } = event;
    const {
      id,
      data: {
        current: { type, model },
      },
    } = active;

    previouslyDraggableTypeRef.current = type;

    setActiveId(id);

    setActiveModelData({
      model: model,
      dndType: type,
    });
    setClonedContainers(containers);
    onDragStartOuter?.();
  }

  function onDragOver({ active, over }: DragOverEvent) {
    if (isMobile) return;
    const overId = over?.id;

    // if over the empty space or in the same container
    if (overId == null || active.id in containersWithItems) {
      return;
    }

    const overContainerId = findContainerByIdOrItemId(overId);
    const activeContainerId = findContainerByIdOrItemId(active.id);

    if (!overContainerId || !activeContainerId) {
      return;
    }

    // if over the different container
    if (activeContainerId !== overContainerId) {
      setContainers((containers) => {
        const activeItems = containers[activeContainerId];
        const overItems = containers[overContainerId];

        const overIndex = overItems.indexOf(overId as string);
        const activeIndex = activeItems.indexOf(active.id as string);

        let newIndex: number = overItems.length + 1;

        if (!(overId in containers)) {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;
          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        return {
          ...containers,
          [activeContainerId]: containers[activeContainerId].filter(
            (itemId) => itemId !== active.id
          ),
          [overContainerId]: [
            ...containers[overContainerId].slice(0, newIndex),
            containers[activeContainerId][activeIndex],
            ...containers[overContainerId].slice(newIndex),
          ],
        };
      });

      recentlyMovedToNewContainer.current = true;
    }
  }

  function onDragCancel() {
    if (isMobile) return;
    if (clonedContainers) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setContainers(clonedContainers);
    }

    setActiveId(null);
    setActiveModelData({ ...initialActiveModelData });
  }

  function handleDragEnd(event: DragEndEvent) {
    if (isMobile) return;

    const { active, over } = event;

    // when move groups
    if (active.id in containers && over?.id) {
      setClonedContainerIds(containerIds);
      const activeIndex = containerIds.indexOf(active.id as string);
      const overIndex = containerIds.indexOf(over.id as string);
      const isChangedOrder = activeIndex !== overIndex;
      if (!isChangedOrder) return;

      const newgroupIds = arrayMove(containerIds, activeIndex, overIndex);
      setContainerIds(newgroupIds);

      setActiveId(null);
      setActiveModelData({ ...initialActiveModelData });
      onContainersReorder?.(newgroupIds, active.data?.current?.model);

      return;
    }

    const activeContainer = findContainerByIdOrItemId(active.id);
    if (!activeContainer) {
      setActiveId(null);
      setActiveModelData({ ...initialActiveModelData });
      return;
    }

    const overId = over?.id;

    if (overId == null) {
      setActiveId(null);
      setActiveModelData({ ...initialActiveModelData });
      return;
    }

    const overChannelGroupId = findContainerByIdOrItemId(overId) as string;
    const overChannelGroup = modelsRegistryRef.current[overChannelGroupId];

    const activeChannelGroupId = getParentContainerId
      ? getParentContainerId(active)
      : active?.data?.current?.model?.serverData?.parentId;

    const isDropInNewGroup =
      activeChannelGroupId && overChannelGroupId !== activeChannelGroupId;

    if (overChannelGroupId) {
      const activeIndex = containers[activeContainer].indexOf(
        active.id as string
      );
      const overIndex = containers[activeContainer].indexOf(overId as string);

      // don't need to shuffle & handle the items if there are no changed possition
      if (activeIndex === overIndex && !isDropInNewGroup) {
        return;
      }

      const newOrderedChannelsIds = arrayMove(
        containers[overChannelGroupId],
        activeIndex,
        overIndex
      );

      setContainers((containers) => ({
        ...containers,
        [overChannelGroupId]: newOrderedChannelsIds,
      }));

      if (isDropInNewGroup) {
        // when move channel to the new container
        onMoveItemToNewGroup(
          active?.data?.current?.model,
          overIndex,
          overChannelGroupId
        );
      } else {
        // when reorder in the same container
        onItemsReorder(newOrderedChannelsIds, overChannelGroup as ContainerT);
      }
    }

    setActiveId(null);
    setActiveModelData({ ...initialActiveModelData });
  }
}
