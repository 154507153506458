import { ThreeDotsVerticalBtnTooltip } from '@foundationPathAlias/components/buttons/square';
import { classNames, useLongPress } from '@foundationPathAlias/utilities';
export type SidebarChannelItemPropsType = {
  cn?: string;
  lContent: JSX.Element;
  children: string | React.ReactNode;
  newMessagesCount?: number | null;
  active?: boolean;
  tooltipContent?: string;
  noDotsBtn?: boolean;
  onDotsBtnClick?: (e: React.SyntheticEvent<Element, Event>) => void;
  onClick: () => void;
  onContextMenu?: (e: React.SyntheticEvent) => void;
  onLongPress?: (e: React.SyntheticEvent) => void;
  geRef?: (el: HTMLDivElement) => void;
};

import { useState } from 'react';
import { SquareBadge } from '../community-elements';

export function SidebarChannelItem(props: SidebarChannelItemPropsType) {
  const {
    lContent,
    children,
    cn,
    active,
    newMessagesCount,
    noDotsBtn,
    onClick,
    onDotsBtnClick,
    onContextMenu,
    onLongPress,
    geRef,
    tooltipContent,
  } = props;

  const longPressHandlers = useLongPress(onLongPress as any);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={cn}
      onContextMenu={onContextMenu}
      onTouchStart={longPressHandlers.onTouchStart as any}
      onTouchEnd={longPressHandlers.onTouchEnd as any}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      ref={geRef}
    >
      <div
        className={classNames(
          'sidebar-channel-item animation-classic relative flex min-h-[32px] w-full cursor-pointer items-center pl-[16px] pr-[42px] text-text-placeholder hover:bg-background-hover hover:text-text-primary dark:hover:bg-background-hover-dark',
          active &&
            'bg-background-selected bg-hovered-selected text-text-primary dark:bg-hovered-selected-dark'
        )}
        onClick={onClick}
      >
        <div
          className={classNames(
            'mr-[8px] h-[16px] w-[16px]',
            active && 'themed-text'
          )}
        >
          {lContent}
        </div>

        <p
          className={classNames(
            'text-body16R leading-[24px]',
            (newMessagesCount || active) &&
              'themed-text text-body16SB leading-[24px]'
          )}
        >
          {children}
        </p>

        <div className="absolute right-[8px] flex items-center space-x-[2px]">
          {newMessagesCount && <SquareBadge>{newMessagesCount}</SquareBadge>}
          {!noDotsBtn && isHovered && (
            <ThreeDotsVerticalBtnTooltip
              className={'h-[24px] w-[24px]'}
              onClick={(e) => {
                console.log('e -> ', e);
                e.preventDefault();
                e.stopPropagation();
                onDotsBtnClick?.(e);
              }}
              darkMode={false}
              tooltipContent={tooltipContent as string}
            />
          )}
        </div>
      </div>
    </div>
  );
}
