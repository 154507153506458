import {
  PlusBtnTooltip,
  ThreeDotsVerticalBtnTooltip,
} from '@foundationPathAlias/components/buttons/square';
import { classNames } from '@foundationPathAlias/utilities';

export const BUTTONS_SIZE_CN = 'h-[24px] w-[24px]';

export type Props = {
  cn?: string;
  onPlusBtnClick: () => void;
  onDotsBtnClick: () => void;
  plusBtnTooltipContent: string;
  dotsBtnTooltipContent: string;
};

export function RightActions(props: Props) {
  const {
    cn,
    onPlusBtnClick,
    onDotsBtnClick,
    plusBtnTooltipContent,
    dotsBtnTooltipContent,
  } = props;

  return (
    <div className={classNames('flex items-center px-[4px]', cn)}>
      <PlusBtnTooltip
        className={BUTTONS_SIZE_CN}
        TolltipProps={{
          triggerWrapperCn: BUTTONS_SIZE_CN,
        }}
        onClick={onPlusBtnClick}
        darkMode={false}
        tooltipContent={plusBtnTooltipContent}
      />
      <ThreeDotsVerticalBtnTooltip
        className={BUTTONS_SIZE_CN}
        onClick={onDotsBtnClick}
        darkMode={false}
        tooltipContent={dotsBtnTooltipContent}
      />
    </div>
  );
}
