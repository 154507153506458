import { classNames, useLongPress } from '@foundationPathAlias/utilities';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';

import { RightActions } from './RightActions';

export type SidebarChannelHeadingPropsType = {
  cn?: string;
  plusBtnTooltipContent?: string;
  dotsBtnTooltipContent?: string;
  children?: string | React.ReactNode;
  noRightActions?: boolean;
  noLeftIcon?: boolean;
  onHeadingClick?: () => void;
  onPlusClick?: () => void;
  onDotsClick?: () => void;
  onContextMenu?: (e: React.SyntheticEvent) => void;
  onLongPress?: (e: React.SyntheticEvent) => void;
  getHeadingRef?: (el: HTMLDivElement) => void;
  label: string;
};

export function _SidebarChannelHeading(props: SidebarChannelHeadingPropsType) {
  const {
    children,
    noLeftIcon,
    cn,
    plusBtnTooltipContent,
    dotsBtnTooltipContent,
    noRightActions,
    label,
    onHeadingClick,
    onContextMenu,
    onLongPress,
    onPlusClick,
    onDotsClick,
    getHeadingRef,
  } = props;

  const [open, setOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const hoverElRef = useRef<HTMLElement | null>(null);

  const longPressHandlers = useLongPress(onLongPress as any);

  const showRightButtons = isHovered && !noRightActions;

  return (
    <div className={classNames('relative', cn)}>
      <div
        className="animation-classic flex text-text-placeholder hover:bg-background-hover hover:text-text-primary dark:bg-background-hover-dark"
        onContextMenu={onContextMenu}
        onTouchStart={longPressHandlers.onTouchStart as any}
        onTouchEnd={longPressHandlers.onTouchEnd as any}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        ref={(el) => {
          hoverElRef.current = el;
          getHeadingRef?.(el as HTMLDivElement);
        }}
      >
        <button
          className={classNames(
            ' relative flex w-full flex-1 cursor-pointer items-center pl-[16px] text-body18SB'
          )}
          onClick={() => {
            setOpen(!open);

            onHeadingClick?.();
          }}
        >
          {!noLeftIcon && (
            <div className="mr-[8px] h-[24px] w-[24px]">
              {
                <ChevronRightIcon
                  className={classNames(
                    'transition-all duration-500 ease-in-out',
                    open && 'rotate-90'
                  )}
                />
              }
            </div>
          )}

          <p className={'leading-[26px]'}>{label}</p>
        </button>

        {showRightButtons && (
          <RightActions
            onPlusBtnClick={onPlusClick as () => void}
            onDotsBtnClick={onDotsClick as () => void}
            plusBtnTooltipContent={plusBtnTooltipContent as string}
            dotsBtnTooltipContent={dotsBtnTooltipContent as string}
          />
        )}
      </div>

      {children && (
        <div
          className={classNames(
            'w-full overflow-hidden transition-all duration-500 ease-in-out',
            open ? 'max-h-[1000px]' : 'max-h-0'
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export const SidebarChannelHeading = observer(_SidebarChannelHeading);
