import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

import { ToastTypesEnum } from '@foundationPathAlias/components';
import {
  ArrowRightOnRectangleIcon,
  Cog6ToothIcon,
  EyeIcon,
  PlusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { CommunityModel } from '@mainApp/src/stores/Community.model';
import { useTranslation } from 'react-i18next';
import { MenuItem } from './MenuItem';
import { Separator } from './Separator';

type Props = {
  close: () => void;
};
export function _Menu(props: Props) {
  const { close } = props;
  const { capStore, communityStore, toastStore, createChannelStore } =
    useMultipleInjection([
      IOC_TOKENS.capStore,
      IOC_TOKENS.communityStore,
      IOC_TOKENS.toastStore,
      IOC_TOKENS.createChannelStore,
    ]);

  const { t } = useTranslation(['common', 'cap']);

  const activeCommunity = communityStore.activeCommunity.data;

  async function copyCurrentUrlToClipboard() {
    const url = window.location.href;
    try {
      await navigator.clipboard.writeText(url);
      toastStore.show({
        type: ToastTypesEnum.SUCCESS,
        title: t('urlCopiedToClipboard'),
        content: t('youCanShareIt'),
      });
    } catch (err) {
      toastStore.show({
        type: ToastTypesEnum.ERROR,
        title: t('errors.error'),
        content: t('errors.failedToCopyURLToClipboard'),
      });
    }
    close();
  }

  return (
    <ul className="rounded-[5px] border-[1px] py-[8px] shadow-shadow-menu-dekstop">
      {(activeCommunity?.isUserAdmin || activeCommunity?.isUserOwner) && (
        <>
          <MenuItem
            icon={<EyeIcon />}
            label={t('markAllAsRead')}
            onClick={() => {
              alert('Mark all as read in the community');
              close();
            }}
          />
          <Separator />
        </>
      )}

      <MenuItem
        icon={<UserPlusIcon />}
        label={t('invitePeople')}
        onClick={copyCurrentUrlToClipboard}
      />
      {(activeCommunity?.isUserAdmin || activeCommunity?.isUserOwner) && (
        <>
          <MenuItem
            icon={<PlusIcon />}
            label={t('createChannelGroup')}
            onClick={() => {
              createChannelStore.show('group');
              close();
            }}
          />
          <Separator />

          <MenuItem
            icon={<Cog6ToothIcon />}
            label={t('cap:communitySettings')}
            onClick={() => {
              capStore.setIsShow(true);
              close();
            }}
          />
        </>
      )}

      {/*  
      {activeCommunity?.isUserOwner && (
        <MenuItem
          icon={<BellIcon />}
          label={t('notificationSettings')}
          onClick={() => {
            alert('Notification settings');
          }}
        />
      )}
        */}

      {(activeCommunity?.isUserMember || activeCommunity?.isUserAdmin) &&
        !activeCommunity?.isUserOwner && (
          <>
            <Separator />
            <MenuItem
              className="themed-text-error"
              icon={<ArrowRightOnRectangleIcon className="themed-text-error" />}
              label={t('leaveCommunity')}
              onClick={() => {
                const { id, slugName } = (activeCommunity as CommunityModel)
                  .serverData;
                communityStore.leaveCommunity(id, slugName as string);
                close();
              }}
            />
          </>
        )}
    </ul>
  );
}

export const Menu = observer(_Menu);
