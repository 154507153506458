import { Sidebar as SidebarFND } from '@foundationPathAlias/components/sidebar';
import { Sidebar } from '@mainApp/src/components/sidebar/Sidebar';
import { AccountSettings } from '@mainApp/src/modules/account-settings';
import { observer } from 'mobx-react-lite';

import { useAuthUI } from '@mainApp/src/components/auth/useAuthUI';
import { usePostAuthOnboardingUI } from '@mainApp/src/components/post-auth-onboarding/usePostAuthOnboardingUI';
import { useCommunityColors } from '@mainApp/src/hooks';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

type Props = {
  children: React.ReactNode;
  simplifiedMode?: boolean;
};

export function _SidebarLayout(props: Props) {
  const { children, simplifiedMode } = props;
  useAuthUI();
  usePostAuthOnboardingUI();
  useCommunityColors();

  const { systemStore, dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.dimensionsStore,
  ]);
  const isDesktop = !dimensionsStore.isMobile;

  return (
    <div>
      <SidebarFND
        open={systemStore.isSidebarOpened}
        docked={isDesktop}
        styles={{
          sidebar: {
            maxWidth: '324px',
            overflowY: isDesktop ? 'hidden' : 'auto',
          },
        }}
        contentClassName={`${isDesktop ? 'w-80%' : 'w-full'}`}
        onSetOpen={() => {
          systemStore.setIsSidebarOpened(!systemStore.isSidebarOpened);
        }}
        sidebarContent={<Sidebar simplifiedMode={simplifiedMode} />}
      >
        {children}
      </SidebarFND>
    </div>
  );
}

const SidebarLayout = observer(_SidebarLayout);

export const getSidebarLayout =
  (
    options = {
      simplifiedMode: false,
    }
  ) =>
  (page: React.ReactNode) =>
    (
      <SidebarLayout {...options}>
        {page}
        {/* TODO: maybe create a dedicated layout for the chats? */}
        <AccountSettings />
      </SidebarLayout>
    );
