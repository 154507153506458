import { TitleLoader, withLoading } from '../loaders';

import { MenuPopup } from './sidebar-context-menu';
type Props = {
  title: string;
};

export function _CommunityTitle(props: Props) {
  const { title } = props;
  return (
    <>
      <h1 className="text-body18SB">{title}</h1>
      <MenuPopup />
    </>
  );
}

export const CommunityTitle = withLoading(_CommunityTitle, TitleLoader);
