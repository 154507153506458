import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type DndItemSortableParams<T> = {
  setNodeRef: (node: HTMLElement | null) => void;
  style: React.CSSProperties;
  dataModel: T;
  // dnd kit attributes and listeners
  attributes: any;
  listeners: any;
};

type DndItemSortableProps<T> = {
  children: (params: DndItemSortableParams<T>) => JSX.Element;
  id: string;
  index: number;
  dataModel: T;
};

export function DndItemSortable<T>({
  id,
  dataModel,
  children,
}: DndItemSortableProps<T>) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      data: {
        type: 'item',
        model: dataModel,
      },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return children({
    setNodeRef,
    style,
    dataModel,
    attributes,
    listeners,
  });
}
