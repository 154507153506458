type SquareBadgePropsType = {
  children: string | number;
};

export function SquareBadge(props: SquareBadgePropsType) {
  const { children } = props;

  return (
    <span className="flex h-[20px] w-[21px] items-center justify-center rounded-[3px] bg-primary-100 text-sm14T leading-[inherit] text-text-primary-dark">
      {children}
    </span>
  );
}
