// based on: https://github.com/balloob/react-sidebar#readme
// @ts-ignore
import ReactSidebar from 'react-sidebar';

export type SidebarPropsType = {
  open: boolean;
  onSetOpen: () => void;
  children: React.ReactNode | React.ReactNode[];
  sidebarContent: React.ReactNode | React.ReactNode[];
  docked?: boolean;
  /**
   * Width in pixels you can start dragging from the edge when the sidebar is closed. */
  touchHandleWidth?: number;
  /**
   * Distance the sidebar has to be dragged before it will open/close after it is released.
   */
  dragToggleDistance?: number;
  contentClassName?: string;

  styles?: {
    root?: any;
    sidebar?: any;
    content?: any;
    overlay?: any;
    dragHandle?: any;
  };
};

export function Sidebar(props: SidebarPropsType) {
  const { children, sidebarContent, styles = {}, ...otherProps } = props;

  return (
    <ReactSidebar
      sidebar={sidebarContent}
      styles={{
        ...styles,
        sidebar: {
          backgroundColor: 'white',
          ...(styles.sidebar || {}),
        },
      }}
      {...otherProps}
    >
      {children}
    </ReactSidebar>
  );
}
