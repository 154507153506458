import { SidebarChannelHeading } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChannelGroupModeEnum,
  useChannelGroupContextMenuContext,
} from './channel-group-context-menu';

type Props = {
  channelGroupModel: ChannelGroupModel;
  onPlusClick: (channelGroupId: string) => void;
  children: React.ReactNode;
  noLeftIcon?: boolean;
  cn?: string;
};

export function ChannelGroupHeadingWithMenu(props: Props) {
  const { channelGroupModel, onPlusClick, noLeftIcon, children, cn } = props;

  const i18n = useTranslation('channel');
  const { t } = i18n;

  const communityStore = useInjection(IOC_TOKENS.communityStore);

  const isUserAdminOrOwner =
    communityStore.activeCommunity.data?.isUserAdminOrOwner;

  const channelGroupId = channelGroupModel.id;
  const channelGroupName = channelGroupModel.name;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const channelGroupContextMenuCtx = useChannelGroupContextMenuContext();

  const showContextMenu = (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    channelGroupContextMenuCtx.showContextMenu(
      anchorEl as HTMLElement,
      ChannelGroupModeEnum.GROUP,
      channelGroupModel
    );
  };

  return (
    <SidebarChannelHeading
      key={channelGroupId}
      cn={classNames('my-[12px] w-full', cn)}
      label={channelGroupName}
      noRightActions={!isUserAdminOrOwner}
      dotsBtnTooltipContent={t<string>('channelSettings.channelGroupSettings')}
      plusBtnTooltipContent={t<string>('modal.createChannel')}
      onPlusClick={() => onPlusClick(channelGroupId)}
      onDotsClick={() => {
        alert('Channel group settings');
      }}
      getHeadingRef={(anchorEl) => {
        setAnchorEl(anchorEl);
      }}
      onContextMenu={showContextMenu}
      onLongPress={showContextMenu}
      noLeftIcon={noLeftIcon}
    >
      {children}
    </SidebarChannelHeading>
  );
}
