import {
  SquareIconButtonBaseTooltip,
  SquareIconButtonBaseTooltipPropsType,
} from '@foundationPathAlias/components/buttons/SquareIconButtonBaseTooltip';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

export type ThreeDotsVerticalBtnTooltipPropsType = Omit<
  SquareIconButtonBaseTooltipPropsType,
  'children'
>;

export function ThreeDotsVerticalBtnTooltip(
  props: ThreeDotsVerticalBtnTooltipPropsType
) {
  return (
    <SquareIconButtonBaseTooltip {...props}>
      {<EllipsisVerticalIcon />}
    </SquareIconButtonBaseTooltip>
  );
}
