import { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { SidebarCommunitiesWithLoader } from './SidebarCommunities';
const SidebarUserPanel = dynamic(
  () => import('./SidebarUserPanel').then((module) => module.SidebarUserPanel),
  {
    ssr: false,
  }
);
const SidebarControlPanel = dynamic(
  () =>
    import('./SidebarControlPanel').then(
      (module) => module.SidebarControlPanel
    ),
  {
    ssr: false,
  }
);

import { SquareIconButtonBase } from '@foundationPathAlias/components';
import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { useDetectScrollEnd } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useGetUserCommunitiesPaginated } from '@mainApp/src/stores/hooks';
import { SidebarGuestCommunity } from './SidebarGuestCommunity';

type Props = {
  simplifiedMode?: boolean;
};

export function _SidebarCommunitiesColumn(props: Props) {
  const { simplifiedMode } = props;
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollEnd } = useDetectScrollEnd(0.1, scrollRef);

  const { userStore, communityStore, authStore } = useMultipleInjection([
    IOC_TOKENS.userStore,
    IOC_TOKENS.communityStore,
    IOC_TOKENS.authStore,
  ]);

  const { query } = useRouter();
  const [startCommunitiesFetch, setStartCommunitiesFetch] = useState(false);

  useEffect(() => {
    if (scrollEnd) {
      const pageInfo = communityStore.userCommunities.meta.pageInfo;
      const hasPreviousPage = pageInfo?.hasPreviousPage;
      if (!hasPreviousPage) return;

      const nextPageVars = {
        ...communityStore.userCommunitiesPaginationVars,
        before: pageInfo.startCursor,
      };

      communityStore.setUserCommunitiesPaginationVars(nextPageVars);
    }
  }, [scrollEnd]);

  useEffect(() => {
    if (userStore && query.community_slug && authStore.logged) {
      setStartCommunitiesFetch(true);
    }
  }, [userStore, query.community_slug, authStore.logged]);

  const variables = communityStore.userCommunitiesPaginationVars;

  useGetUserCommunitiesPaginated(variables, startCommunitiesFetch);

  const userCommunities =
    communityStore.userCommunities.data?.valuesArray.slice().reverse() || [];

  const isIntiialLoading =
    communityStore.userCommunities.loading && userCommunities.length === 0;

  const isNonInitialLoading =
    communityStore.userCommunities.loading && userCommunities.length > 0;

  return (
    <div className="relative flex h-full max-h-full w-[76px] flex-col  bg-background-tetriary pb-[194px] pt-[14px] dark:bg-background-tetriary-dark">
      {/* <div className="fixed left-0 top-0 min-h-full w-[76px] bg-background-tetriary pt-[14px] dark:bg-background-tetriary-dark"> */}
      <div className="relative flex h-full">
        <div
          ref={scrollRef}
          className="hide-scrollbar relative flex max-h-full flex-1 flex-col items-center space-y-[16px] overflow-auto px-[14px] pb-[16px]"
        >
          {!simplifiedMode &&
            communityStore.activeCommunity.data?.isUserVisitorOrNonMember && (
              <SidebarGuestCommunity
                activeCommunity={communityStore.activeCommunity.data}
                isLoading={communityStore.activeCommunity.loading}
              />
            )}

          <SidebarCommunitiesWithLoader
            userCommunities={userCommunities}
            // show skeleton loader only for the initial loading. For pagination there will be other loader
            isLoading={isIntiialLoading}
            activeCommunityId={
              communityStore.activeCommunity.data?.serverData.id
            }
            logged={authStore.logged}
            activeCommunity={communityStore.activeCommunity.data}
          />

          {isNonInitialLoading && (
            <div className="px-[14px]">
              <SquareIconButtonBase
                className="mb-[14px] h-[44px] w-[44px] shrink-0 rounded-[5px] bg-background-primary dark:bg-background-tetriary-dark"
                onClick={() => {}}
              >
                <SmallLoader />
              </SquareIconButtonBase>
            </div>
          )}
        </div>
      </div>

      <div className="absolute bottom-0 mx-[16px] flex flex-col  pt-[16px]">
        <SidebarControlPanel simplifiedMode={simplifiedMode} />
        <SidebarUserPanel />
      </div>
      {/* </div> */}
    </div>
  );
}

export const SidebarCommunitiesColumn = observer(_SidebarCommunitiesColumn);
