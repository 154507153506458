import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

type SortableChildrenParams<T> = {
  setNodeRef: (element: HTMLElement | null) => void;
  style: React.CSSProperties;
  dataModel: T;
  // DnD Kit attributes and listeners
  attributes: any;
  listeners: any;
};

type Props<T> = {
  children: (params: SortableChildrenParams<T>) => JSX.Element;
  id: string;
  items: string[];
  dataModel: T;
  disabled?: boolean;
};

export function DndContainerSortable<T>(props: Props<T>) {
  const { id, items, children, dataModel } = props;

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
      model: dataModel,
    },
    animateLayoutChanges,
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
  };

  return children({
    setNodeRef,
    style,
    dataModel,
    attributes,
    listeners,
  });
}
