import { classNames } from '@foundationPathAlias/utilities';
import React from 'react';

type MenuItemProps = {
  onClick: () => void;
  label: string;
  className?: string;
  icon?: React.ReactNode;
};

export const MenuItem = ({
  onClick,
  label,
  className,
  icon,
}: MenuItemProps) => {
  return (
    <li
      role="button"
      onClick={onClick}
      className={classNames(
        'themed-text flex h-[44px] cursor-pointer items-center bg-background-primary px-[16px] py-[12px] text-body16R hover:bg-hovered-selected dark:bg-background-tetriary-dark',
        ' dark:hover:bg-hovered-selected-dark',
        className
      )}
    >
      <span className="mr-[16px] h-[20px] w-[20px] min-w-[20px]">{icon}</span>
      {label}
    </li>
  );
};
