import { classNames } from '@foundationPathAlias/utilities';

export const Separator = ({ className }: { className?: string }) => (
  <hr
    className={classNames(
      'my-[4px] h-[1px] w-full bg-element-subtle dark:bg-element-subtle-dark',
      className
    )}
  />
);
