import { observer } from 'mobx-react-lite';

import { ContextMenu } from '@foundationPathAlias/components';

import { Menu } from './Menu';
import { useChannelGroupContextMenuContext } from './channelGroupContextMenuContext';

export function _GroupContextMenu() {
  const { isShow, anchorEl, mode, data, hideContextMenu } =
    useChannelGroupContextMenuContext();

  if (!anchorEl) return null;

  return (
    <ContextMenu
      anchorEl={anchorEl}
      withoutAnchorAsTrigger
      isShow={isShow}
      onClose={hideContextMenu}
    >
      <Menu mode={mode} data={data} close={hideContextMenu} />
    </ContextMenu>
  );
}

export const GroupContextMenu = observer(_GroupContextMenu);
