import { classNames } from '../../utilities';

export type CommunityLogoItemPropsType = {
  // TOOD: type
  community: any;
  onClick?: (communityModel: any) => void;
  logoSrc?: string;
  active?: boolean;
  notificationsCount?: string;
  notificationDot?: boolean;
};

export function CommunityLogoItem(props: CommunityLogoItemPropsType) {
  const {
    logoSrc,
    onClick,
    community,
    active,
    notificationsCount,
    notificationDot,
  } = props;
  return (
    <button
      onClick={() => {
        onClick?.(community);
      }}
      className={classNames(
        'h-[48px] w-[48px] rounded-[5px] border-[3px]  p-[2px]',
        active
          ? 'cursor-default border-text-primary'
          : 'hover-el border-transparent'
      )}
    >
      <div className="relative h-full w-full rounded-[5px] bg-element-normal dark:bg-element-normal-dark">
        {notificationsCount && (
          <span className="absolute -right-[12px] -top-[10px]  rounded-[20px] border-[3px] border-background-secondary bg-background-primary-dark px-[7px] dark:border-background-secondary-dark dark:bg-background-primary">
            <span className="flex text-sm14T leading-normal text-text-primary-dark dark:text-text-primary">
              {notificationsCount}
            </span>
          </span>
        )}
        {notificationDot && (
          <span className=" absolute -right-[8px] -top-[8px] h-[18px] w-[18px] rounded-full border-[3px] border-background-secondary bg-background-primary-dark dark:border-background-secondary-dark dark:bg-background-primary" />
        )}
        <img
          className="h-full w-full rounded-[5px] object-cover"
          src={logoSrc}
        />
      </div>
    </button>
  );
}
