import { SidebarChannelsColumn } from './SidebarChannelsColumn';
import { SidebarCommunitiesColumn } from './SidebarCommunitiesColumn';

type Props = {
  /** on some pages like Explore there shouldn't be rendered channels and active community */
  simplifiedMode?: boolean;
};

export function Sidebar(props: Props) {
  const { simplifiedMode } = props;
  return (
    <nav className="flex h-full overflow-x-hidden bg-background-secondary dark:bg-background-secondary-dark">
      <SidebarCommunitiesColumn simplifiedMode={simplifiedMode} />

      {!simplifiedMode && <SidebarChannelsColumn />}
    </nav>
  );
}
