import { CommunityLogoItem } from '@10x/foundation/src/components';
import { CommunityLogoLoader } from '../loaders/CommunityLogoLoader';
import { withLoading } from '../loaders/withLoading';
export type PropsType = {
  activeCommunity: any;
};
export function _SidebarGuestCommunity(props: PropsType) {
  const { activeCommunity } = props;

  return (
    <div className="border-b-[1px] border-element-normal pb-[16px] dark:border-element-normal-dark">
      <CommunityLogoItem
        community={activeCommunity}
        key={activeCommunity?.id}
        active
        logoSrc={activeCommunity?.getLogoUrl()}
      />
    </div>
  );
}

export const SidebarGuestCommunity = withLoading(
  _SidebarGuestCommunity,
  CommunityLogoLoader
);
