import { CommunityLogoItem } from '@10x/foundation/src/components';

import { useRouter } from 'next/router';

import { SidebarCommunitiesListLoader } from '../loaders';
import { withLoading } from '../loaders/withLoading';

import { paths } from '@mainApp/src/config';

import { CommunityModel } from '@mainApp/src/stores/Community.model';

export type SidebarCommunitiesPropsType = {
  userCommunities: CommunityModel[] | [];
  activeCommunityId?: string;
  activeCommunity: CommunityModel | null;
  logged: boolean;
};

export function SidebarCommunities(props: SidebarCommunitiesPropsType) {
  const { push } = useRouter();
  const { userCommunities, activeCommunityId, activeCommunity } = props;

  if (!userCommunities?.length) return null;

  return (
    <div className="flex-1">
      {activeCommunity && !activeCommunity.isUserVisitorOrNonMember && (
        <CommunityLogoItem
          community={activeCommunity}
          key={activeCommunity.serverData.id}
          active={true}
          logoSrc={activeCommunity.getLogoUrl()}
        />
      )}
      {userCommunities?.map((communityModel) => {
        const isActive = communityModel.serverData.id === activeCommunityId;
        if (isActive) {
          return null;
        }
        return (
          <CommunityLogoItem
            community={communityModel}
            key={communityModel.serverData.id}
            active={isActive}
            onClick={(communityModel) => {
              const newPath = paths.getCommunityPath(
                communityModel.serverData.slugName
              );
              if (!isActive) {
                push(newPath);
              }
            }}
            logoSrc={communityModel.getLogoUrl()}
          />
        );
      })}
    </div>
  );
}

export const SidebarCommunitiesWithLoader = withLoading(
  SidebarCommunities,
  SidebarCommunitiesListLoader
);
