import { classNames } from '@foundationPathAlias/utilities';
import { forwardRef, ReactNode } from 'react';

type DndContainerRepresentationProps<T> = {
  children: (dataModel: T) => ReactNode;
  dataModel: T;
  cn?: string;
};

// Generic version of forwardRef
const _DndContainerRepresentation = <T,>(
  { children, dataModel, cn, ...props }: DndContainerRepresentationProps<T>,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <div {...props} className={classNames('flex space-y-[12px]', cn)} ref={ref}>
      {children(dataModel)}
    </div>
  );
};

// Export forwardRef with generic type support
export const DndContainerRepresentation = forwardRef(
  _DndContainerRepresentation
) as <T>(
  props: DndContainerRepresentationProps<T> & {
    ref?: React.Ref<HTMLDivElement>;
  }
) => ReturnType<typeof _DndContainerRepresentation>;
