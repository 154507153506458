import {
  IOC_TOKENS,
  useInjection,
  useMultipleInjection,
} from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
const BottomSheetFnd = dynamic(
  () =>
    import('@10x/foundation/src/components/bottomsheet/BottomSheet').then(
      (mod) => mod.BottomSheet
    ),
  {
    ssr: false,
  }
);

type StateType = {
  open: null | (() => void);
  close: null | (() => void);
  isAnimationrunning: false;
};

export const _BottomSheet = () => {
  const accountSettingsStore = useInjection(IOC_TOKENS.accountSettingsStore);
  const { dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
  ]);
  const [bottomSheetActions, setBottomsheetActions] = useState<StateType>({
    open: null,
    close: null,
    isAnimationrunning: false,
  });

  const { show, content } = accountSettingsStore.bottomSheetConfig;

  useEffect(() => {
    if (show) {
      bottomSheetActions?.open?.();
    } else {
      bottomSheetActions?.close?.();
    }
  }, [show]);

  useEffect(() => {
    if (!dimensionsStore.isMobile && show) {
      accountSettingsStore.setBottomSheetConfig({
        show: false,
      });
    }
  }, [dimensionsStore.isMobile, show]);

  return (
    <BottomSheetFnd
      className="themed-layout flex"
      getActions={(open, close) => {
        setBottomsheetActions({
          open,
          close,
          isAnimationrunning: false,
        });
      }}
      onClose={() => {
        accountSettingsStore.setBottomSheetConfig({
          show: false,
        });

        accountSettingsStore.bottomSheetConfig?.onClose?.();
      }}
    >
      {content}
    </BottomSheetFnd>
  );
};

export const BottomSheet = observer(_BottomSheet);
