import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@10x/foundation/src/components/modal/Modal';
import {
  ChangesAlert,
  PrimaryButtonWithLoader,
} from '@foundationPathAlias/components';
import { useMinLoading } from '@foundationPathAlias/components/loaders';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { Actions } from '@mainApp/src/modules/account-settings/common/Actions';
import {
  RedirectionIdsEnum,
  RedirectionURLQueriesEnum,
} from '@mainApp/src/services';
import { ToastTypesEnum } from '@mainApp/src/stores';
import { removeQueryParamsFromRouter } from '@mainApp/src/utils';

import { BottomSheet } from './common/BottomSheet';
import { SidebarMenu } from './sidebar-menu';

export function _AccountSettings() {
  const { accountSettingsStore, dimensionsStore, toastStore } =
    useMultipleInjection([
      IOC_TOKENS.accountSettingsStore,
      IOC_TOKENS.dimensionsStore,
      IOC_TOKENS.toastStore,
    ]);
  const { t } = useTranslation('common');
  const stackStore = accountSettingsStore.stackStore;

  const stackVisibleItem = stackStore.visibleItem;
  const stackPrevItem = stackStore.prevItem;
  const stackNextItem = stackStore.nextItem;

  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (!query) return;
    const paymentRedirect = query[RedirectionURLQueriesEnum.PAYMENT_REDIRECT];
    if (paymentRedirect === RedirectionIdsEnum.ACCOUNT_SETTINGS) {
      if (query[RedirectionURLQueriesEnum.ERROR]) {
        toastStore.show({
          type: ToastTypesEnum.ERROR,
          title: t('errors.error'),
          content: t('errors.subscriptionPaymentError'),
        });
        delete router.query[RedirectionURLQueriesEnum.ERROR];
      } else {
        const proUsername = query[RedirectionURLQueriesEnum.PRO_USERNAME];
        if (typeof proUsername === 'string') {
          accountSettingsStore.completeSubscription(proUsername);
        }
      }
      delete router.query[RedirectionURLQueriesEnum.PAYMENT_REDIRECT];
      delete router.query[RedirectionURLQueriesEnum.PRO_USERNAME];

      removeQueryParamsFromRouter(router, [
        RedirectionURLQueriesEnum.PAYMENT_REDIRECT,
        RedirectionURLQueriesEnum.PRO_USERNAME,
      ]);
    }
  }, [query]);

  useEffect(() => {
    if (dimensionsStore.isReady) {
      accountSettingsStore.setInitialSetting();
    }
  }, [dimensionsStore.isMobile, dimensionsStore.isReady]);

  const processingSubscription = useMinLoading(
    accountSettingsStore.processingSubscription,
    1000
  );

  const {
    title: genericChangesAlertTitleKey,
    description: genericChangesAlertDescr,
    firstBtnText: genericChangesAlertFirstBtnText,
    secondBtnText: genericChangesAlertSecondBtnText,
    ...otherGenericChangesAlertConfig
  } = accountSettingsStore.changesAlertConfig;

  return (
    <Modal
      rootInnerWrapperCn="w-full p-0 "
      contentWrapperCn="w-full p-0 "
      contentCn=" flex rounded-[10px] h-full w-full md:w-auto md:h-auto justify-center overflow-hidden"
      open={accountSettingsStore.show}
      onClose={() => {
        accountSettingsStore.setShow(false);
      }}
    >
      <div
        className="themed-layout relative flex min-h-[650px] w-full 
        max-w-[820px]
       "
      >
        {!dimensionsStore.isMobile && <SidebarMenu />}
        <div className="flex flex-1 flex-col overflow-auto pb-[24px] pt-[20px]">
          <div
            className="relative flex w-full flex-1 overflow-hidden 
      md:w-[604px]"
          >
            <div className="relative w-full flex-1 ">
              {stackPrevItem.Component && (
                <stackPrevItem.Component key={stackPrevItem.id} />
              )}
              {stackVisibleItem.Component && (
                <stackVisibleItem.Component
                  key={stackVisibleItem.id}
                  startLeaving={stackStore.startLeaving}
                  onLeaveEnd={() => {
                    stackStore.onLeavingAnimationEnd();
                  }}
                />
              )}

              {stackNextItem.Component && (
                <stackNextItem.Component
                  isNext={true}
                  startEntering={stackStore.startEntering}
                  key={stackNextItem.id}
                  onEnterEnd={() => {
                    stackStore.onEnterAnimationEnd();
                  }}
                />
              )}
            </div>
          </div>
          <Actions />
        </div>

        {/* custom changes alert with the min loading dependency */}
        <ChangesAlert
          contentWrapperCn="border-[1px]"
          title={
            processingSubscription
              ? t('accountSettings.activateSubscription')
              : t('accountSettings.subscriptionIsActive')
          }
          description={
            processingSubscription ? null : t('accountSettings.canChange')
          }
          show={accountSettingsStore.showSuccessProSubscriptionAlert}
          customActions={
            <PrimaryButtonWithLoader
              cn="w-full"
              loading={processingSubscription}
              dynamicColorsMode={true}
              onClick={() => {
                accountSettingsStore.setShowSuccessProSubscriptionAlert(false);
              }}
            >
              {processingSubscription ? t('processing') : t('gotIt')}
            </PrimaryButtonWithLoader>
          }
        />

        {/* generic changes alert  */}
        <ChangesAlert
          contentWrapperCn="border-[1px]"
          titleCn="text-sm14SB"
          descriptionCn="text-sm14R"
          firstBtnCn="text-sm14SB"
          secondBtnCn="text-sm14SB"
          title={t(genericChangesAlertTitleKey as string)}
          description={t(genericChangesAlertDescr as string)}
          firstBtnText={t<string>(genericChangesAlertFirstBtnText as string)}
          secondBtnText={t<string>(genericChangesAlertSecondBtnText as string)}
          show={otherGenericChangesAlertConfig.show as boolean}
          {...otherGenericChangesAlertConfig}
        />

        <BottomSheet />
      </div>
    </Modal>
  );
}

export const AccountSettings = observer(_AccountSettings);
