import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dynamic from 'next/dynamic';

import { CommunityTitle } from './CommunityTitle';

import { SidebarSeparator } from '@mainApp/src/components/sidebar/SidebarSeparator';
import { paths } from '@mainApp/src/config';
import { useJoinCommunityAction } from '@mainApp/src/hooks';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  RedirectionURLActionsEnum,
  RedirectionURLQueriesEnum,
} from '@mainApp/src/services';
import { removeQueryParamsFromRouter } from '@mainApp/src/utils';
import { ChannelItemWithMenu } from '../ChannelItemWithMenu';
import { ChannelGroupHeadingWithMenu } from './ChannelGroupHeadingWithMenu';
// import { ChannelGroups } from './ChannelGroups';
import {
  ChannelGroupContextMenuProvider,
  GroupContextMenu,
} from './channel-group-context-menu';
// import { DndReorderContainers } from './dnd/DndReorderContainers';
import { DndReorderContainers } from '@10x/foundation/src/components/dnd/dnd-reorder-containers';
import { ChannelModel } from '@mainApp/src/stores/Channel.model';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';

const JoinCommunityButton = dynamic(() => import('./JoinCommunityButton'), {
  ssr: false,
});
const JoinCommunityDescription = dynamic(
  () => import('./JoinCommunityDescription'),
  {
    ssr: false,
  }
);

export function _SidebarChannelsColumn() {
  const {
    channelStore,
    communityStore,
    authStore,
    createChannelStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.channelStore,
    IOC_TOKENS.authStore,
    IOC_TOKENS.communityStore,
    IOC_TOKENS.createChannelStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const [reorderError, setReorderError] = useState<string | null>(null);

  const { t } = useTranslation(['common', 'channel']);

  const router = useRouter();
  const { push, query } = router;
  const queryChannelSlug = query.channel_slug;
  const communitySlug = query?.community_slug;

  const joinCommunityAction = useJoinCommunityAction();

  useEffect(() => {
    const communityId = communityStore.activeCommunity.data?.serverData.id;

    const slug = query?.community_slug;
    if (slug) {
      fetchChannelGroups(slug as string);
      // TODO: Investigate how to implement urql optimistic update with variable policy for network-only or cache-and-network

      // proceeds optimistic responses and initial network fetch.
      communityStore.setSubscriptionCommunityBySlugOnce(slug as string);
      // for the use case when the user changes on the same page by email code auth status from non-member to member and need to re-fetch from BE only the actual data.
      if (communityId) {
        communityStore.getAndSetActiveCommunity(communityId);
      }
    }
  }, [query, authStore.logged]);

  useEffect(() => {
    if (!router.isReady) return;

    const communityId = communityStore.activeCommunity.data?.serverData.id;
    const scheduledActionsQuery = RedirectionURLQueriesEnum.SCHEDULED_ACTIONS;
    const scheduledAction = query[scheduledActionsQuery];
    if (scheduledAction && communityId) {
      // TODO: for simplicitynow it's only join but when there will be multiple
      // actions need to create a dedicated service/handling for the
      // scheduled actions in queue
      const isNonMemberOrVisitor =
        communityStore.activeCommunity?.data?.isUserVisitorOrNonMember;

      // TODO: not sure if it's ok to keep it in the sidebar. Should think about this location
      if (
        scheduledAction === RedirectionURLActionsEnum.JOIN &&
        isNonMemberOrVisitor
      ) {
        communityStore
          .joinCommunity(communityId, query?.community_slug as string)
          .finally(() => {
            delete router.query[scheduledActionsQuery];
          });
      }

      // should remove state from URL
      removeQueryParamsFromRouter(router, [
        RedirectionURLQueriesEnum.SCHEDULED_ACTIONS,
      ]);
      // router.replace({ pathname, query }, undefined, { shallow: true });
    }
  }, [communityStore.activeCommunity?.data?.serverData?.id, router.isReady]);

  async function fetchChannelGroups(communitySlug: string) {
    const community = await communityStore.getCommunityBySlugName(
      communitySlug
    );

    if (!community) {
      return;
    }

    const communityId = community.serverData.id;
    channelStore.resetChannelGroups();
    await channelStore.getChannelGroups(communityId);
  }

  const groups = channelStore.channelNormalGroups.data;

  let dndGroupsWithChannels: Record<string, string[]> = {};
  const dndGroupsAndChannelsRegistry: Record<
    string,
    ChannelGroupModel | ChannelModel
  > = {};

  if (groups.length) {
    dndGroupsWithChannels = groups.reduce((acc, channelGroup) => {
      dndGroupsAndChannelsRegistry[channelGroup.id] = channelGroup;
      acc[channelGroup.id] = channelGroup.channels.map((channelModel) => {
        const id = channelModel.serverData.id;
        dndGroupsAndChannelsRegistry[id] = channelModel;
        return id;
      });
      return acc;
    }, {} as Record<string, string[]>);
  }

  const isUserAdminOrOwner =
    communityStore.activeCommunity.data?.isUserAdminOrOwner;

  const channelSettingsLabel = t('channel:channelSettings.label');

  return (
    <aside className="flex h-full w-[248px] flex-col pb-[19px] ">
      <div>
        <div className="flex h-[64px] items-center justify-between pl-[16px] pr-[8px] pt-[4px] ">
          <CommunityTitle
            title={communityStore.activeCommunity.data?.serverData.name || ''}
            isLoading={communityStore.activeCommunity.loading}
          />
        </div>
        <SidebarSeparator />

        <div className="flex min-h-[64px] flex-col items-start  pb-[15px] pl-[16px] pr-[20px] pt-[4px] text-body16SB text-text-secondary  dark:text-text-secondary">
          <JoinCommunityDescription
            show={communityStore.activeCommunity.data?.isUserVisitorOrNonMember}
          />
          <div className="flex flex-1 items-center">
            <span>
              {t('members', {
                count:
                  communityStore.activeCommunity.data?.serverData.members
                    .totalCount || 0,
              })}
            </span>
            <i
              className="mx-[8px] mt-[4px] block h-[6px] w-[6px] rounded-full
         bg-text-secondary dark:bg-text-secondary-dark"
            />
            <span>
              {communityStore.activeCommunity.data?.serverData.onlineMembers
                .totalCount || 0}{' '}
              {t('online').toLowerCase()}
            </span>
          </div>

          <JoinCommunityButton
            show={Boolean(
              communityStore.activeCommunity.data?.isUserVisitorOrNonMember
            )}
            onClick={joinCommunityAction}
          />
        </div>
        <SidebarSeparator />
      </div>

      <div className="sidebar-channels-wrapper scrollbar-track-rounded-full scrollbar-thumb-rounded-full flex-1 overflow-auto scrollbar-thin scrollbar-track-element-subtle scrollbar-thumb-color-4">
        <ChannelGroupContextMenuProvider>
          {Boolean(channelStore.channelNormalGroups.data.length) && (
            <DndReorderContainers<ChannelGroupModel, ChannelModel>
              containersWithItems={dndGroupsWithChannels}
              containersAndItemsRegistry={dndGroupsAndChannelsRegistry}
              findContainerById={(groupId: string) => {
                const container = groups.find((group) => group.id === groupId);

                if (!container) {
                  throw new Error('Container not found');
                }
                return container;
              }}
              reorderError={Boolean(reorderError)}
              isMobile={isMobile}
              onDragStart={() => {
                if (reorderError) {
                  setReorderError(null);
                }
              }}
              onMoveItemToNewGroup={async (
                channelModel: ChannelModel,
                orderIndex: number,
                newGroupId: string
              ) => {
                const { communityId, id } = channelModel.serverData;
                const { error } = await channelStore.updateChannel(
                  communityId,
                  id,
                  {
                    channelGroupId: newGroupId,
                    order: orderIndex,
                  },
                  true
                );

                if (error) {
                  setReorderError(error);
                }
              }}
              onContainersReorder={async (
                groupIdsOrdered: string[],
                activeModel: ChannelGroupModel
              ) => {
                const communityId = activeModel.communityId;

                const error = await channelStore.updateChannelGroupsOrder(
                  communityId,
                  groupIdsOrdered
                );

                if (error) {
                  setReorderError(error);
                }
              }}
              onItemsReorder={async (
                channelIdsOrdered: string[],
                parentChannelGroup: ChannelGroupModel
              ) => {
                const error = await channelStore.updateChannelsOrderInGroup(
                  parentChannelGroup.communityId,
                  parentChannelGroup.id,
                  channelIdsOrdered
                );

                if (error) {
                  setReorderError(error);
                }
              }}
              getParentContainerId={(dndDraggableItem) => {
                return dndDraggableItem?.data?.current?.model.serverData
                  .parentId;
              }}
              renderContainerDragOverlay={(dataModel) => {
                return (
                  <ChannelGroupHeadingWithMenu
                    onPlusClick={() => null}
                    channelGroupModel={dataModel}
                  >
                    <div className="my-[12px] flex flex-col space-y-[12px]">
                      {dataModel.channels.map((channelModel) => {
                        const channelName = channelModel.serverData.name;
                        return (
                          <ChannelItemWithMenu
                            key={channelName}
                            isUserAdminOrOwner={false}
                            tooltipContent={channelSettingsLabel}
                            active={false}
                            newMessagesCount={null}
                            channelModel={channelModel}
                            onClick={() => null}
                          />
                        );
                      })}
                    </div>
                  </ChannelGroupHeadingWithMenu>
                );
              }}
              renderItemDragOverlay={(dataModel) => {
                return (
                  <ChannelItemWithMenu
                    isUserAdminOrOwner={false}
                    tooltipContent={channelSettingsLabel}
                    active={false}
                    newMessagesCount={null}
                    channelModel={dataModel}
                    onClick={() => null}
                  />
                );
              }}
              ContainerContentComponent={({ children, dataModel }) => {
                return (
                  <ChannelGroupHeadingWithMenu
                    channelGroupModel={dataModel}
                    onPlusClick={(id) => {
                      createChannelStore.setCurrentChannelGroupId(id);
                      createChannelStore.show();
                    }}
                  >
                    {children}
                  </ChannelGroupHeadingWithMenu>
                );
              }}
              ItemComponent={({ dataModel }) => {
                const channelSlug = dataModel.serverData.slug;
                return (
                  <ChannelItemWithMenu
                    isUserAdminOrOwner={Boolean(isUserAdminOrOwner)}
                    tooltipContent={channelSettingsLabel}
                    active={queryChannelSlug === channelSlug}
                    newMessagesCount={null}
                    channelModel={dataModel}
                    onClick={() => {
                      push(
                        paths.getChannelPath(
                          communitySlug as string,
                          channelSlug as string
                        )
                      );
                    }}
                  />
                );
              }}
            />
          )}
          <GroupContextMenu />
        </ChannelGroupContextMenuProvider>
      </div>
    </aside>
  );
}

export const SidebarChannelsColumn = observer(_SidebarChannelsColumn);
