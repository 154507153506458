import {
  OutlineButton,
  PrimaryButtonWithLoader,
} from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Separator } from './Separator';

export function _Actions() {
  const { t } = useTranslation('common');

  const { accountSettingsStore, dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const activeSettingsStore = accountSettingsStore.activeSettingStore;
  // only Account store has these data
  // @ts-ignore
  if (!activeSettingsStore || !activeSettingsStore?.showActions) return null;
  // @ts-ignore
  const actionsData = activeSettingsStore?.actionsData;

  return (
    <div className="flex-end flex items-end">
      <div className="flex flex-1 flex-col">
        <Separator cn="my-0" />

        <div className="flex flex-1 items-end">
          <div
            className={classNames(
              'mt-[24px] flex flex-1 justify-end space-x-[16px] px-[24px]'
            )}
          >
            {!dimensionsStore.isMobile && (
              <OutlineButton
                cn={classNames('min-h-[42px] w-auto rounded-[5px]')}
                onClick={() => {
                  actionsData.cancelAction();
                }}
              >
                {t(actionsData.getCancelActionText())}
              </OutlineButton>
            )}
            <PrimaryButtonWithLoader
              dynamicColorsMode={true}
              // @ts-ignore
              // loading={true}
              loading={activeSettingsStore.isLoading}
              cn={classNames(
                'min-h-[42px] w-auto rounded-[5px] flex-1 md:flex-initial',
                false && 'opacity-50'
              )}
              onClick={() => {
                actionsData?.proceedAction();
              }}
              disabled={
                // only Account store has these data
                // @ts-ignore
                activeSettingsStore.isLoading ||
                // @ts-ignore
                activeSettingsStore.isError ||
                // @ts-ignore
                activeSettingsStore.isDisableNextAction
              }
            >
              {t(actionsData.getProceedActionText())}
            </PrimaryButtonWithLoader>
          </div>
        </div>
      </div>
    </div>
  );
}

export const Actions = observer(_Actions);
